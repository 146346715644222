<template>
  <div class="all">
    <Nav></Nav>
    <div class="news-con">
      <div class="news-title">
        <div class="new-text">
          <div class="title"></div>
          <div class="text">{{ titleInfo.title }}</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="news-list">
        <div v-for="p in navList" :key="p.id" class="whole_box">
          <div class="whole_box_content">
            <div
              class="img"
              :style="{ backgroundImage: `url(` + p.videoimgURL + `)` }"
              @click="onlookPost(p.id)"
            ></div>
            <div class="title_s" @click="onlookPost(p.id)">
              {{ p.title }}
            </div>
            <div class="eventTime">{{ p.eventTime }}</div>
          </div>
        </div>
      </div>
      <div class="news-bottom">
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="downTotal"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/videoNav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  components: {
    Nav,
    Bottom
  },
  data: function() {
    return {
      navList: [], // 二级的稿件
      titleInfo: {},
      page: 1,
      currentPage: 1,
      downTotal: 1
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("mxx", this.$route.query.id);
      RD.fixedTopic(this.$route.query.id)
        .posts(thiz.page, 20)
        .then(data => {
          console.log("datamx", data);
          data.list.forEach(i => {
            i.videoimgURL = RD.video(i.id).poster_URL();
          });
          thiz.navList = data.list;
          thiz.titleInfo = data.item;
          thiz.currentPage = data.pager.currentPage;
          thiz.downTotal = data.pager.total;
        });
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this;
      thiz.page = val;
      thiz.currentPage = val;
      thiz.reload(thiz.page);
    },
    // 点击视频封面或标题，查看详情
    onlookPost(postId) {
      this.$router.push({
        path: "/videoDetail.html",
        query: { id: postId }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.all {
  width: 1200px;
  margin: 0 auto;
  .news-con {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .news-title {
      height: 70px;
      width: 100%;
      .new-text {
        width: 50%;
        text-align: left;
        margin-top: 20px;
        margin-left: 5px;
        font-size: 1.4rem;
        display: flex;
        padding-top: 10px;
        .title {
          width: 5px;
          height: 35px;
          background: #000;
        }
        .text {
          margin-left: 5px;
          font-size: 1.8rem;
        }
      }
      .line {
        width: 100%;
        height: 2px;
        margin-top: 10px;
        margin-left: 5px;
        background-color: #bfbfbf;
      }
    }
    .news-list {
      width: 100%;
      background-color: #f1f1f1;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .whole_box {
        height: 0;
        box-sizing: border-box;
        margin: 10px;
        position: relative;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
        background-color: #f1f1f1;
        overflow: hidden;
        .whole_box_content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .img {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            z-index: 10;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #f6f6f6;
            cursor: pointer;
          }
          .title_s {
            height: 8%;
            transition: all 0.5s linear;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 15px;
            text-align: left;
            font-size: 1.4rem;
            left: 0;
            right: 0;
            margin-left: 10px;
            line-height: 25px;
            cursor: pointer;
          }
          .eventTime {
            height: 10%;
            text-align: left;
            margin-left: 10px;
          }
          .time {
            height: 10%;
            position: absolute;
            left: 135px;
            .count {
              height: 20px;
              display: inline-block;
            }
            .tag {
              display: inline-block;
              height: 20px;
              width: 20px;
              cursor: pointer;
              margin-left: 10px;
              background: no-repeat url(../../assets/tagup.png);
            }
            .like {
              height: 20px;
              display: inline-block;
              cursor: pointer;
              margin-left: 10px;
              width: 20px;
              background: no-repeat url(../../assets/like.png);
            }
            .com {
              height: 20px;
              display: inline-block;
              cursor: pointer;
              margin-left: 10px;
              width: 20px;
              background: no-repeat url(../../assets/comment.png);
            }
          }
        }
      }
    }
    .news-bottom {
      height: 100px;
      width: 100%;
      position: relative;
      .page-container {
        position: absolute;
        right: 50px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .whole_box {
    width: calc(100% - 10px);
    padding-top: calc((100% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 600px) {
  .whole_box {
    width: calc(100% - 10px);
    padding-top: calc((100% - 20px) * 1.35);
  }
}

@media only screen and (min-width: 960px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}

@media only screen and (min-width: 1200px) {
  .whole_box {
    width: calc(33.33% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 1600px) {
  .whole_box {
    width: calc(25% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
</style>
